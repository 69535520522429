span.temperature-value {
  display: flex;
  flex-direction: column;
  font-size: var(--label-font-size);
  transition: color 0.5s ease;
}

.temperature-value > span.caption {
  font-size: var(--regular-font-size);
}

.temperature-value > span.caption > :not(:last-child) {
  margin-right: 5px;
}

.temperature-value > span.large:first-child {
  font-size: var(--large-font-size);
}

.select-station {
  position: relative;
}

.select-station input {
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  padding: var(--label-padding);
  border: var(--input-border);
  border-radius: var(--label-border-radius);
}

.select-station ul {
  position: absolute;
  top: var(--input-height);
  left: 0px;
  max-height: 400px;
  overflow: auto;
  margin: 0;
  list-style: none;
  background: #fff;
  padding: var(--label-padding);
  border: var(--input-border);
  box-shadow: 1px 1px 2px #eee;
  z-index: 1;
}

.select-station ul > li {
  padding: 5px 0px;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
}

.select-station ul > li:hover,
.select-station ul > li:hover.selected {
  background-color: #eee;
  cursor: pointer;
}
.select-station ul > li.selected {
  background-color: #ddd;
}

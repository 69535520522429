:root {
  --app-width: 360px;
  --app-one-third-width: 120px;
  --app-max-width: 540px;
  --app-spacing: 14px;
  --app-loading-color: #aaa;

  --regular-font-size: 14px;
  --label-font-size: 24px;
  --large-font-size: 72px;

  --label-padding: 8px;
  --label-background-color: #ddd;
  --label-font-weight: normal;
  --label-border-radius: 8px;

  --input-border: 1px solid #ddd;
  --input-height: 47px; /* font height+padding+border */
}

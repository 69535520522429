.wrap {
  display: flex;
  justify-content: space-around;
}

.the-water-temperature {
  min-width: var(--app-width);
  max-width: var(--app-max-width);
  flex-grow: 1;
  margin: var(--app-spacing);
}

.the-water-temperature header,
.the-water-temperature .select-station,
.the-water-temperature > a,
.the-water-temperature > h2,
.the-water-temperature > span,
.the-water-temperature select.choose-comparison,
.the-water-temperature .comparison {
  margin-bottom: var(--app-spacing);
}

.the-water-temperature > h2.latest-reading {
  margin-bottom: 0;
}

.the-water-temperature .select-station input,
.the-water-temperature .select-station ul {
  width: 100%;
  box-sizing: border-box;
}

.the-water-temperature > a,
.the-water-temperature > span,
.the-water-temperature .compare-with,
.the-water-temperature footer {
  display: block;
  padding-left: var(--label-padding);
}

h2 {
  padding: var(--label-padding);
  margin: 0;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  background-color: var(--label-background-color);
  border-radius: var(--label-border-radius);
}

.the-water-temperature select.choose-comparison {
  height: 45px;
  width: 100%;
}

.loading {
  color: var(--app-loading-color);
}

.error {
  color: red;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--label-padding);
  background-color: var(--label-background-color);
}

header > h1 {
  padding: 0;
  margin: 0;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
}
